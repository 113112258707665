<script setup lang="ts">
import { defineProps } from "vue";
import { IProps } from "@/shared/ui/BaseButton/types";
import { ButtonFactory } from "@/shared/ui/BaseButton/hooks";

// define props
const props = defineProps<IProps>();

// define component
const component = ButtonFactory(props.type);
</script>

<template>
  <component v-if="component"
             :is="component"
             :text="text"
  />
</template>

<style scoped>

</style>