<script setup lang="ts">
import { defineProps, withDefaults, computed } from "vue";
import { IProps } from "@/shared/ui/BaseRouterLink/types";
import { RouterLinkFactory } from "@/shared/ui/BaseRouterLink/hooks";

// some incomprehensible problems with type in the IDE
// @ts-ignore
const props = withDefaults(defineProps<IProps>(), {
  to: { name: 'home'}
});
const component = computed(() => RouterLinkFactory(props.type));
</script>

<template>
  <component v-if="component"
             :is="component"
             :to="to">
    <slot></slot>
  </component>
</template>

<style>
</style>
