import { createStore } from 'vuex'
import vuelidate from "@/shared/consts/store-modules/vuelidate";

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    vuelidate,
  }
})
