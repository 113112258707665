<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">

</script>
<template>
  <header>
    <div class="relative h-fit">
      <img class="w-screen h-auto object-cover" src="@/widgets/Header/assets/img/top-img.webp" alt="Top image">
    </div>
  </header>
</template>

<style scoped>
</style>