import {BaseButtonFactory, IPropsType} from "@/shared/ui/BaseButton/types";
import {DarkPurpleVioletButton} from "@/shared/ui/BaseButton/DarkPurpleVioletButton";
import {Component} from "vue";

export const ButtonFactory: BaseButtonFactory = (type) => {
    let component: Component<IPropsType> = DarkPurpleVioletButton;

    switch (type) {
        case "dark-purple-violet":
            component = DarkPurpleVioletButton;
            break;
        default:
            break;
    }

    return component;
}