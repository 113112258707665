import {Errors, Getters, GettersTypes, Mutations, MutationsTypes, State} from "@/shared/types/store-modules/vuelidate";
import {GetterTree, Module, MutationTree} from "vuex";

const state: State = {
    errors: {}
}

const getters: GetterTree<State, any> & Getters = {
    [GettersTypes.GET_ERRORS]: (state: State): Errors => state.errors
}

const mutations: MutationTree<State> & Mutations = {
    [MutationsTypes.ADD_ERROR]: (state: State, payload): void => {
        const hasErrors = Array.isArray(state.errors[payload.name])
        if (!hasErrors) {
            state.errors[payload.name] = [];
        }
        state.errors[payload.name].push(payload.error);
    },
    [MutationsTypes.CLEAR_ERRORS]: (state: State): void => {
        Object.keys(state.errors).forEach(field => {
            delete state.errors[field];
        })
    },
    [MutationsTypes.CLEAR_ERROR]: (state: State, payload): void => {
        if (payload.name in state.errors) {
            delete state.errors[payload.name];
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations
} as Module<State, any>