export type Errors = {[p: string]: string[]}

export interface State {
    errors: Errors
}

export enum GettersTypes {
    GET_ERRORS = "GET_ERRORS"
}

export enum MutationsTypes {
    ADD_ERROR = "ADD_ERROR",
    CLEAR_ERRORS = "CLEAR_ERRORS",
    CLEAR_ERROR = "CLEAR_ERROR",
}

export interface Getters<S = State> {
    [GettersTypes.GET_ERRORS](state: S): Errors
}

export interface Mutations<S = State> {
    [MutationsTypes.ADD_ERROR](state: S, payload: {name: string, error: string}): void
    [MutationsTypes.CLEAR_ERRORS](state: S): void
    [MutationsTypes.CLEAR_ERROR](state: S, payload: {name: string}): void
}