import {BaseRouterLinkFactory, IPropsType} from "@/shared/ui/BaseRouterLink/types";
import { DarkPurpleRouterLink } from "@/shared/ui/BaseRouterLink/DarkPurpleRouterLink/";
import {Component} from "vue";

export const RouterLinkFactory: BaseRouterLinkFactory = (type) => {
    let component: Component<IPropsType> = DarkPurpleRouterLink;

    switch (type) {
        case "dark-purple":
            component = DarkPurpleRouterLink;
            break;
        default:
            break;
    }

    return component;
}